import axios from 'axios';
import { APIEndpoints } from 'helpers/APILists';
import { ExponentialToDecimal } from 'helpers/Validation';
import { formatDate } from 'helpers/DateFormatter';
import { MESSAGE_TYPE } from '../../../common/common.constants';
import { DPSACTIONS } from './DPS.constants';
import { dpsLockedFieldDetails, searchDPSFunction, sortFunction } from './DPS.helpers';
import { setCurrentView } from '../../Home/Home.actions';

const trimDPSData = (dpsData) => {
    const dpsDataObject = dpsData;
    for (const key in dpsDataObject) {
        if (typeof dpsDataObject[key] === 'string') {
            dpsDataObject[key] = dpsDataObject[key]?.trim();
        }
    }
    return dpsDataObject;
};

export const setDisplayStatusSortOrder = (columnName) => (dispatch, getState) => {
    const { DPS: { sortDisplayStatusAscOrder } } = getState();
    dispatch({
        type: 'DISPLAY_STATUS_SORTORDER',
        payload: {
            sortDisplayStatusAscOrder: !sortDisplayStatusAscOrder,
            sortColumn: columnName
        }
    });
    dispatch(sortDPS(columnName, !sortDisplayStatusAscOrder));
};

export const setFunctionalLocationSortOrder = (columnName) => (dispatch, getState) => {
    const { DPS: { sortFunctionalLocationAscOrder } } = getState();
    dispatch({
        type: 'FUNCTIONAL_LOCATION_SORTORDER',
        payload: {
            sortFunctionalLocationAscOrder: !sortFunctionalLocationAscOrder,
            sortColumn: columnName
        }
    });
    dispatch(sortDPS(columnName, !sortFunctionalLocationAscOrder));
};

export function exampleAction(text) {
    return dispatch => {
        dispatch({
            type: 'AUTH_SUCCESS',
            payload: { success: true },
            meta: { message: text }
        });
    };
}

const displayDPSUnSavedChangesLabel = () => (dispatch, getState) => {
    const { DPS: { dpsDataAll, editDPSData } } = getState();
    let originalData = dpsDataAll.find(x => x.RowId === editDPSData.RowId);
    const modifiedData = editDPSData;
    let labelState = false;
    originalData = {
        ...originalData
    };
    delete modifiedData.FormLocationDescription;
    delete modifiedData.FormIcssSoftTag;
    // eslint-disable-next-line guard-for-in
    for (const key in modifiedData) {
        if ((modifiedData[key] === '' || modifiedData[key] === null) && (originalData[key] === null || originalData[key] === '')) {
            modifiedData[key] = '';
            originalData[key] = '';
        }
        if (modifiedData[key] !== originalData[key]) {
            labelState = true;
            break;
        }
    }
    dispatch({
        type: DPSACTIONS.UPDATE_DPS_UNSAVED_CHANGES_LABEL,
        payload: {
            unSavedChanges: labelState
        }
    });
};

export const getAllDPSData = () => async (dispatch, getState) => {
    const { Home: { selectedAssetAliasName, bpRegionNameAlias }, AppData: { isMRATOnline }, DPS: { dpsSearchString } } = getState();
    const filterData = { Region: bpRegionNameAlias, Asset: selectedAssetAliasName };
    if (isMRATOnline) {
        dispatch({
            type: DPSACTIONS.GET_DPSRECORDS_PENDING,
            payload: {
                tableLoading: true
            }
        });
        await axios.post(APIEndpoints.DPS_GetAssetDPSList, filterData)
            .then((res) => {
                const dpsData = res.data;
                const totalCount = res.data.length;
                const applyExponentialToDecimal = (data) => ExponentialToDecimal(data ?? '');
                dispatch({
                    type: DPSACTIONS.GET_DPSRECORDS_SUCCESS,
                    payload: {
                        tableLoading: false,
                        dpsDataAll: dpsData.map(elem => ({
                            ...elem,
                            Asset: elem.Asset ?? '',
                            TrfId: elem.TrfId ? elem.TrfId.trim() : '',
                            FailureModeHighCurrentMA: applyExponentialToDecimal(elem?.FailureModeHighCurrentMA),
                            FailureModeLowCurrentMA: applyExponentialToDecimal(elem?.FailureModeLowCurrentMA),
                            HmiLrv: applyExponentialToDecimal(elem?.HmiLrv),
                            HmiUrv: applyExponentialToDecimal(elem?.HmiUrv),
                            InstrumentCalibratedLrv: applyExponentialToDecimal(elem?.InstrumentCalibratedLrv),
                            InstrumentCalibratedUrv: applyExponentialToDecimal(elem?.InstrumentCalibratedUrv),
                            ReCalibrationTolerance: applyExponentialToDecimal(elem?.ReCalibrationTolerance),
                            DesignElementResponseTime: applyExponentialToDecimal(elem?.DesignElementResponseTime),
                            FailTolerance: applyExponentialToDecimal(elem?.FailTolerance),
                            HighHighSetpoint: applyExponentialToDecimal(elem?.HighHighSetpoint),
                            LowLowSetpoint: applyExponentialToDecimal(elem?.LowLowSetpoint),
                            SetPointHigh: applyExponentialToDecimal(elem?.SetPointHigh),
                            LowSetPoint: applyExponentialToDecimal(elem?.LowSetPoint),
                            SwitchSetpoint: applyExponentialToDecimal(elem?.SwitchSetpoint),
                            ValveSize: applyExponentialToDecimal(elem?.ValveSize),
                            ToleranceInInstrumentEu: applyExponentialToDecimal(elem?.ToleranceInInstrumentEu),
                            TargetLeakageRate: applyExponentialToDecimal(elem?.TargetLeakageRate)
                        })),
                    }
                });
                !dpsSearchString && dispatch({
                    type: DPSACTIONS.GET_DPSRECORDS_TOTALCOUNT_SUCCESS,
                    payload: {
                        totalCount
                    }
                });
            })
            .catch((err) => {
                dispatch({
                    type: DPSACTIONS.GET_DPSRECORDS_FAILURE,
                    payload: {
                        tableLoading: false,
                        dpsDataAll: [],
                        totalCount: 0
                    },
                    meta: { messageType: MESSAGE_TYPE.ERROR, message: err.message, exception: err }
                });
            });
    }
};

export const reSetDPSTotalCount = () => async (dispatch) => {
    dispatch({
        type: DPSACTIONS.RESET_DPS_TOTAL_COUNT,
        payload: {
            totalCount: 0
        }
    });
};

export const searchDPS = (searchVal) => async (dispatch, getState) => {
    await dispatch(getAllDPSData());
    dispatch({
        type: DPSACTIONS.GET_SEARCHDPSRECORDS_PENDING,
        payload: {
            tableLoading: true,
            dpsSearchString: searchVal
        }
    });
    const { DPS: { dpsDataAll, sortColumn, sortDisplayStatusAscOrder, sortFunctionalLocationAscOrder } } = getState();
    searchDPSFunction(searchVal, dpsDataAll)
        .then(searchedData => {
            const totalCount = searchedData.length;
            dispatch({
                type: DPSACTIONS.GET_SEARCHDPSRECORDS_SUCCESS,
                payload: {
                    tableLoading: false,
                    dpsTableData: searchedData,
                    totalCount
                }
            });
        }
        ).then(() => { sortColumn === 'SapFloc' ? dispatch(sortDPS(sortColumn, sortFunctionalLocationAscOrder)) : dispatch(sortDPS(sortColumn, sortDisplayStatusAscOrder)); })
        .catch(error => {
            dispatch({
                type: DPSACTIONS.GET_SEARCHDPSRECORDS_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
        }
        );
};

export const sortDPS = (colForSorting, sortAscOrder) => (dispatch, getState) => {
    dispatch({
        type: DPSACTIONS.GET_SORTDPSRECORDS_PENDING,
        payload: {
            tableLoading: true
        }
    });

    const { DPS: { dpsDataAll, dpsSearchString, dpsTableData } } = getState();
    if (dpsSearchString) {
        sortFunction(dpsTableData, colForSorting, sortAscOrder)
            .then(sortedData => {
                dispatch({
                    type: DPSACTIONS.GET_SORTDPSRECORDS_SUCCESS,
                    payload: {
                        tableLoading: false,
                        dpsTableData: sortedData
                    }
                });
            }
            )
            .catch(error => {
                dispatch(sortDpsRecordFailure(error));
            }
            );
    } else {
        sortFunction(dpsDataAll, colForSorting, sortAscOrder)
            .then(sortedData => {
                dispatch({
                    type: DPSACTIONS.GET_SORTDPSRECORDS_SUCCESS,
                    payload: {
                        tableLoading: false,
                        dpsDataAll: sortedData
                    }
                });
            }
            )
            .catch(error => {
                dispatch(sortDpsRecordFailure(error));
            }
            );
    }
};

export const setDataForAddDPS = (initialState) => (dispatch) => {
    dispatch({
        type: DPSACTIONS.ADDDPS_SET_INITIALDATA,
        payload: {
            addDPSData: initialState,
            sapFlocExist: false
        }
    });
};

export const resetDataForAddDPS = (initialState) => (dispatch) => {
    dispatch({
        type: DPSACTIONS.ADDDPS_RESET_DATA,
        payload: {
            addDPSData: initialState,
            sapFlocExist: false
        }
    });
};

export const updateDataForAddDPS = (changeObj) => (dispatch) => {
    dispatch({
        type: DPSACTIONS.ADDDPS_UPDATE_DATA,
        payload: {
            ...changeObj
        }
    });
};

export const setDataForEditDPS = (existingState) => (dispatch) => {
    let editDPSData = existingState;
    const sapFloc = existingState.SapFloc;
    editDPSData = {
        ...editDPSData
    };
    dispatch({
        type: DPSACTIONS.EDITDPS_SET_EXISTINGDATA,
        payload: {
            editDPSData,
            sapFloc
        }
    });
};

export const resetDataForEditDPS = () => (dispatch, getState) => {
    const { DPS: { dpsDataAll, editDPSData } } = getState();
    let resetEditDpsData = dpsDataAll.find(x => x.RowId === editDPSData.RowId);
    resetEditDpsData = {
        ...resetEditDpsData
    };
    dispatch({
        type: DPSACTIONS.EDITDPS_RESET_DATA,
        payload: {
            editDPSData: resetEditDpsData,
            unSavedChanges: false
        }
    });
};

export const updateDataForEditDPS = (changeObj) => (dispatch) => {
    dispatch({
        type: DPSACTIONS.EDITDPS_UPDATE_DATA,
        payload: {
            ...changeObj
        }
    });
    dispatch(displayDPSUnSavedChangesLabel());
};

const searchDPSData = (dispatch, dpsSearchString, sortColumn, sortFunctionalLocationAscOrder, sortDisplayStatusAscOrder) => {
    dpsSearchString && dispatch(searchDPS(dpsSearchString));
    !dpsSearchString && sortColumn && (sortColumn === 'SapFloc'
        ? dispatch(sortDPS(sortColumn, sortFunctionalLocationAscOrder))
        : dispatch(sortDPS(sortColumn, sortDisplayStatusAscOrder)));
};

export const PostAddDPSData = () => async (dispatch, getState) => {
    const { DPS: { dpsSearchString, sortColumn, sortDisplayStatusAscOrder, sortFunctionalLocationAscOrder, addDPSData } } = getState();
    addDPSData.ModifiedBy = addDPSData.CreatedBy;
    addDPSData.LastModifiedDate = addDPSData.CreatedDate;
    dispatch({
        type: DPSACTIONS.ADD_DPSRECORD_PENDING,
        payload: {
            tableLoading: true
        }
    });
    await axios.post(APIEndpoints.DPS_AddEditDps, trimDPSData(addDPSData))

        .then((res) => {
            if (res.data.RowId) {
                dispatch({
                    type: DPSACTIONS.ADD_DPSRECORD_SUCCESS,
                    payload: {
                        tableLoading: false
                    }
                });
                dispatch(setCurrentView('dps'));
            } else {
                dispatch({
                    type: DPSACTIONS.DPSRECORD_FLOC_EXIST,
                    payload: {
                        sapFlocExist: true
                    }
                });
            }
            dispatch(getAllDPSData()).then(() => {
                searchDPSData(dispatch, dpsSearchString, sortColumn, sortFunctionalLocationAscOrder, sortDisplayStatusAscOrder);
            });
        }).catch((error) => {
            dispatch({
                type: DPSACTIONS.EDIT_DPSRECORD_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
            dispatch(setCurrentView('dps'));
        });
};

export const PutEditDPSData = (userName) => async (dispatch, getState) => {
    const { DPS: { dpsSearchString, sortColumn, sortDisplayStatusAscOrder, sortFunctionalLocationAscOrder, editDPSData } } = getState();
    editDPSData.ModifiedBy = userName?.name;
    editDPSData.LastModifiedDate = formatDate(new Date());
    dispatch({
        type: DPSACTIONS.EDIT_DPSRECORD_PENDING,
        payload: {
            tableLoading: true
        }
    });
    await axios.put(APIEndpoints.DPS_AddEditDps, trimDPSData(editDPSData))
        .then((res) => {
            dispatch({
                type: DPSACTIONS.EDIT_DPSRECORD_SUCCESS,
                payload: {
                    tableLoading: false
                }
            });
            dispatch(getAllDPSData()).then(() => {
                searchDPSData(dispatch, dpsSearchString, sortColumn, sortFunctionalLocationAscOrder, sortDisplayStatusAscOrder);
            });
        }).catch((error) => {
            dispatch({
                type: DPSACTIONS.EDIT_DPSRECORD_FAILURE,
                payload: {
                    tableLoading: false,
                },
                meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
            });
        });
};

const sortDpsRecordFailure = (error) => (dispatch) => {
    dispatch({
        type: DPSACTIONS.GET_SORTDPSRECORDS_FAILURE,
        payload: {
            tableLoading: false,
        },
        meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
    });
};

export const getDpsSourceMapping = () => async (dispatch, getState) => {
    const { Home: { regionName, selectedAssetName } } = getState();
    const filterData = { Region: regionName };
    try {
        axios.post(APIEndpoints.GetDPSSourceMappings, filterData)
            .then((res) => {
                let dpsLockedFields = [];
                if (res.data.length !== 0) {
                    const activeRecords = res.data?.filter((obj) => obj?.Status);
                    const fetchFieldsByAsset = activeRecords?.filter((obj) => obj?.Asset?.toLowerCase() === selectedAssetName?.toLowerCase());
                    dpsLockedFields = dpsLockedFieldDetails(fetchFieldsByAsset.length === 0 ? activeRecords : fetchFieldsByAsset);
                }
                dispatch({
                    type: DPSACTIONS.DPS_LOCKED_FIELDS,
                    payload: {
                        dpsLockedFields
                    }
                });
            });
    } catch (error) {
        dispatch({
            meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
        });
    }
};

export const getCorrectiveDpsMapping = () => async (dispatch, getState) => {
    const { Home: { trfMasterList } } = getState();
    try {
        axios.get(APIEndpoints.ADMIN_GetCorrectiveDPSMapping)
            .then((res) => {
                const correctiveDPSMapping = res.data.map(item => ({
                    ...item,
                    Trfid: trfMasterList.find(data => data?.RowId === item?.Trfid)?.TrfId,
                    CorrectiveTrfid: trfMasterList.find(data => data?.RowId === item?.CorrectiveTrfid)?.TrfId,
                }));
                dispatch({
                    type: DPSACTIONS.CORRECTIVE_DPS_MAPPING,
                    payload: {
                        correctiveDPSMapping
                    }
                });
            });
    } catch (error) {
        dispatch({
            meta: { messageType: MESSAGE_TYPE.ERROR, message: error.message, exception: error }
        });
    }
};
